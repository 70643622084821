.pricing-row-container {
    font-family: "Plain Medium";
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    line-height: 1rem;
    min-height: 1em;

    @media (max-width: 600px) {
        border: 1px solid #d6d8da;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 10px;
    }

    .checkbox {
        margin-left: 0px;
        margin-bottom: 23px;
    }

    .pricing-row-label {
        font-weight: bold;
        font-size: 17px;
        color: #000000;
        float: left;
    }

    .pricing-row-amount {
        font-family: "Plain Medium";
        font-size: 15px;
        position: absolute;
        font-weight: 600;
        right: 15px;
        color: #191919;

        &.pet-pricing {
            margin-top: 4px; //HACK: This is needed to fix the alignment of the pricingRowAmount div for pet rows
        }
    }

    .pricing-row-extra-description {
        font-family: Plain;
        width: 100%;
        font-size: 15px;
        text-align: left;
        line-height: 21px;
        color: #737373;
        clear: both;
        margin-top: 20px;
        padding-top: 10px;
    }

    .pet-pricing-row-header {
        float: left;
        border-radius: 5px;
        font-size: 16px;
        width: 100% !important;
        min-width: 350px !important;

        &.has-content {
            margin-bottom: 10px;
        }
    }

    .pricing-row-pet-name {
        font-family: "Plain Medium";
        font-weight: 600;
        font-size: 19px;
        line-height: 24px;
        color: #191919;

        @media (max-width: 600px) {
            font-size: 17px;
        }
    }

    .pricing-row-service-description {
        margin-top: 5px;
        font-family: "Plain";
        max-width: 500px;
        line-height: 21px;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        color: #737373;
    }

    .pet-pricing-row-content {
        &.multiple {
            .pricing-row-service-option {
                border-top: 1px solid #dedede;
                width: 100%;
                padding-top: 5px;
                margin-bottom: 15px;
                height: 45px !important;
            }
            .pricing-row-service-option-groomer {
                width: 100%;
                padding-top: 5px;
                margin-bottom: 10px;
                height: 30px !important;
            }
            .pricing-row-service-option:last-child {
                margin-bottom: 0px !important;
            }
        }
    }
    .multiple-services-selection {
        font-family: "Plain Medium";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
        color: #191919;
        margin-left: 20px;
        cursor: pointer;
        width: 320px;
    }

    .multiple-services-selection-unchecked {
        font-family: "Plain";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        color: #737373;
        margin-left: 20px;
        cursor: pointer;
        width: 320px;
    }
}

.groomer-service-selection {
    padding: 0 15px 0 8px;
    font-family: Plain;
    @media (max-width: 600px) {
        border: none;
        margin-bottom: 0;
    }
}