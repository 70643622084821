.addeventatc {
    display: inline-block;
    position: relative;
    font-family: "Open Sans", Roboto, "Helvetica Neue", Helvetica, Optima, Segoe, "Segoe UI", Candara, Calibri, Arial,
        sans-serif;
    background: #000000;
    color: white;
    font-weight: 600;
    line-height: 100%;
    min-width: 265px;
    font-size: 15px;
    text-decoration: none;
    border: 1px solid transparent;
    padding: 13px 12px 12px 43px;
    -webkit-border-radius: 3px;
    border-radius: 6px;
    cursor: pointer;
    -webkit-font-smoothing: antialiased !important;
    outline-color: rgba(0, 78, 255, 0.5);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    user-select: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.17), 0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
        0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 0 0 0 transparent !important;
}

.addeventatc:hover {
    background-color: #3a3939;
    color: white;
    font-size: 15px;
    text-decoration: none;
}

.addeventatc:active {
    border-width: 2px 1px 0px 1px;
}

.addeventatc-selected {
    background-color: #f9f9f9;
}

.addeventatc .addeventatc_icon {
    width: 18px;
    height: 18px;
    position: absolute;
    z-index: 1;
    left: 12px;
    top: 10px;
    background: url(../images/add-calendar.svg) no-repeat;
    background-size: 18px 18px;
}

.addeventatc .icon {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;
    left: 20px;
    background: url(../images/add-calendar.svg) no-repeat;
    background-size: 20px 20px;
}

.addeventatc .start,
.addeventatc .end,
.addeventatc .timezone,
.addeventatc .title,
.addeventatc .description,
.addeventatc .location,
.addeventatc .organizer,
.addeventatc .organizer_email,
.addeventatc .facebook_event,
.addeventatc .all_day_event,
.addeventatc .date_format,
.addeventatc .alarm_reminder,
.addeventatc .recurring,
.addeventatc .attendees,
.addeventatc .calname,
.addeventatc .uid,
.addeventatc .status,
.addeventatc .method,
.addeventatc .client,
.addeventatc .transp {
    display: none !important;
}

.addeventatc br {
    display: none;
}

.addeventatc_dropdown {
    width: 268px;
    position: absolute;
    padding: 6px 0px 0px 0px;
    font-family: "Open Sans", Roboto, "Helvetica Neue", Helvetica, Optima, Segoe, "Segoe UI", Candara, Calibri, Arial,
        sans-serif;
    color: #000 !important;
    font-weight: 600;
    line-height: 100%;
    background: #fff;
    font-size: 15px;
    text-decoration: none;
    text-align: left;
    margin-left: -1px;
    display: none;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
    -moz-box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 26px;
    transform: scale(0.98, 0.98) translateY(5px);
    z-index: -1;
    transition: transform 0.15s ease;
    user-select: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.addeventatc_dropdown.topdown {
    transform: scale(0.98, 0.98) translateY(-5px) !important;
}

.addeventatc_dropdown span {
    display: block;
    line-height: 100%;
    background: #fff;
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    color: #333;
    font-weight: 600;
    padding: 14px 10px 14px 90px;
    margin: -2px 0px;
}

.addeventatc_dropdown span:hover {
    background-color: #f4f4f4;
    color: #000;
    text-decoration: none;
    font-size: 15px;
}

.addeventatc_dropdown em {
    color: #999 !important;
    font-size: 12px !important;
    font-weight: 400;
}

.addeventatc_dropdown .frs a {
    background: #fff;
    color: #cacaca !important;
    cursor: pointer;
    font-size: 9px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 110% !important;
    padding-left: 10px;
    position: absolute;
    right: 10px;
    text-align: right;
    text-decoration: none;
    top: 5px;
    z-index: 101;
}

.addeventatc_dropdown .frs a:hover {
    color: #999 !important;
}

.addeventatc_dropdown .ateappleical {
    background: url(../images/icon-apple-t5.svg) 30px 40% no-repeat;
    background-size: 22px 100%;
}

.addeventatc_dropdown .ategoogle {
    background: url(../images/icon-google-t5.svg) 30px 50% no-repeat;
    background-size: 22px 100%;
}

.addeventatc_dropdown .ateoffice365 {
    background: url(../images/icon-office365-t5.svg) 30px 50% no-repeat;
    background-size: 18px 100%;
}

.addeventatc_dropdown .ateoutlook {
    background: url(../images/icon-outlook-t5.svg) 30px 50% no-repeat;
    background-size: 22px 100%;
}

.addeventatc_dropdown .ateoutlookcom {
    background: url(../images/icon-outlookcom-t5.svg) 30px 50% no-repeat;
    background-size: 22px 100%;
}

.addeventatc_dropdown .ateyahoo {
    background: url(../images/icon-yahoo-t5.svg) 30px 50% no-repeat;
    background-size: 22px 100%;
}

.addeventatc_dropdown .atefacebook {
    background: url(../images/icon-facebook-t5.svg) 30px 50% no-repeat;
    background-size: 22px 100%;
}

.addeventatc_dropdown .copyx {
    height: 21px;
    display: block;
    position: relative;
    cursor: default;
}

.addeventatc_dropdown .brx {
    height: 1px;
    overflow: hidden;
    background: #e8e8e8;
    position: absolute;
    z-index: 100;
    left: 10px;
    right: 10px;
    top: 9px;
}

.addeventatc_dropdown.addeventatc-selected {
    transform: scale(1, 1) translateY(0px);
    z-index: 99999999999;
}

.addeventatc_dropdown.topdown.addeventatc-selected {
    transform: scale(1, 1) translateY(0px) !important;
}

.addeventatc_dropdown .drop_markup {
    background-color: #f4f4f4;
}
