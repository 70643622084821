*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family:
    Plain,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    "Plain",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  letter-spacing: 0em;
}

@media screen and (max-device-width: 480px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
}

@media screen and (max-device-width: 480px) and (webkit-min-device-pixel-ratio: 0) {
  body {
    letter-spacing: 0.01em;
  }
}

button {
  font-family:
    "Plain Medium",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    "Plain",
    sans-serif;
}

img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiInputLabel-formControl.MuiInputLabel-shrink.Mui-focused {
  color: #0066ff !important;
  font-weight: bold !important;
  padding-right: 8px;
  background-color: #ffffff;
}

span.MuiRadio-root:not(.Mui-checked) {
  span {
    color: #989898 !important;
  }
}

@font-face {
  font-family: Plain;
  src:
    local("Plain Regular"),
    url("../fonts/Plain/plain566.otf") format("truetype");
  font-weight: 400;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Plain Medium";
  src:
    local("Plain Medium"),
    url("../fonts/Plain/plain568.otf") format("truetype");
  font-weight: 600;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Plain Bold";
  src:
    local("Plain Bold"),
    url("../fonts/Plain/plain570.otf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

.btn {
  color: #fff;
  background: #4898f8;
  background-color: #4898f8 !important;
  border: 1px solid #4898f8;
  margin: 10px 0;
}

.field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 0px;

  label,
  input,
  textarea,
  select {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  input,
  textarea,
  select {
    font-size: 14px;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-family: inherit;
    -webkit-appearance: none;
    border-radius: 0;
    padding: 8px 0;
    cursor: text;
    line-height: 18px;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: 0;
    border-bottom: 1px solid #666;
  }

  input:placeholder-shown + label,
  textarea:placeholder-shown + label,
  select:placeholder-shown + label {
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1);
  }

  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder,
  select:focus::-webkit-input-placeholder {
    opacity: 0;
  }

  input:not(:placeholder-shown) + label,
  input:focus + label,
  textarea:not(:placeholder-shown) + label,
  textarea:focus + label,
  select:not(:placeholder-shown) + label,
  select:focus + label {
    transform: translate(0, 0.8rem) scale(1);
    cursor: pointer;
  }
}

#root {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  overflow: auto;

  & > #barkbus-container-public {
    min-height: 100vh;
    max-width: 100%;
    background-color: #fff;
    overflow-y: hidden;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-flex: 1;
    flex: 1 0 auto;
  }
}

.online-booking-wizard-container {
  position: relative;
  display: fixed;
  width: 100%;
  background: #ffffff;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 30px;

  .titles-container {
    text-align: left;

    h1 {
      font-family:
        "Plain Bold",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "Plain",
        sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      display: inline-block;
      flex-direction: column;
      color: #191919;
      margin-top: 0px;
      margin-bottom: 0px;
      letter-spacing: normal;
    }

    h2 {
      font-family:
        "Plain",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "Plain",
        sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 33px;
      color: #191919;
      letter-spacing: normal;
      margin-top: 15px;
      margin-bottom: 0px;
    }
  }

  .form-controls-container {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
  }

  .content-safety {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: left;
    color: #000;

    & > div {
      margin-top: 20px;
    }

    & > div:first-child {
      margin-top: 0px !important;
    }
  }

  .customer-image-add-dog {
    margin-bottom: 40px;
    margin-top: 70px;
  }

  .online-booking-step-container {
    width: 100%;
    text-align: center;
    color: #191919;

    @media (min-width: 600px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100% - 80px);
      overflow: auto;
    }

    .MuiFormHelperText-root {
      font-style: normal;
      font-weight: normal;
      text-align: center;
      font-size: 20px;
      color: red;

      .phone-number {
        text-decoration: none;
        cursor: pointer;
        color: #4a4a4a;
      }
    }

    .returning-customer-home {
      .titles-container {
        margin-top: 20px;

        h2 {
          margin-bottom: 0px;
        }
      }
    }

    .service-area-result-banner {
      font-size: 14px;
      height: 40px;
      padding-top: 10px;

      &.success {
        background-color: #dcffdf;
        color: #2e8a48;
      }

      &.failure {
        background-color: #000000;
        color: #ffffff;
      }

      .service-area-ok-icon {
        position: absolute;
        margin-top: -25px;
        margin-left: 10px;
        border: 1px solid #148441;
        border-radius: 100px;
        height: 50px;
        width: 50px;
        font-size: 30px;
        line-height: 45px;
        background-color: white;
      }
    }

    .lead-address-map {
      border-radius: 6px;
      overflow: hidden;

      &.within-service-area {
        border: 1px solid #148441;
      }
      &.outside-service-area {
        border: 1px solid #000000;
      }
      &.not-geolocated {
        border: 1px solid #a9a9a9;
      }
    }

    .parking-notes {
      width: 100%;
      margin-top: 20px;

      &:hover {
        border-color: #0066ff;
        background: #fff;
      }
    }

    .parking-note-title {
      font-size: 20px;
      margin-bottom: -5px;
    }

    .confirm-page-footer {
      font-size: 16px;
      line-height: 21px;
      text-align: left;
      font-style: normal;
      font-weight: lighter;
      color: #2b273b;
      border-top: 1px solid #c6c6c6;
      padding-top: 20px;
      display: flex;

      & > div {
        width: 50%;
      }
    }

    .confirm-page-price {
      justify-content: center;
      width: 100%;
      border-bottom: 1px solid #000000;
      padding-bottom: 20px;
      font-size: 22px;
      line-height: 21px;
      text-align: center;
      font-style: normal;
      font-weight: bolder;
      color: #2b273b;

      .total {
        display: inline;
        float: left;
      }

      .price {
        display: inline;
        float: right;
      }
    }

    .confirm-page-service {
      justify-content: center;
      width: 100%;
      font-size: 22px;
      line-height: 21px;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      color: #2b273b;
      margin-top: 20px;

      .pet-name {
        font-size: 16px;
        text-align: start;
        width: 250px;
        margin-bottom: 20px;
        max-width: 250px;

        .description {
          font-size: 16px;
          font-weight: lighter;
        }
      }

      .price {
        display: inline;
        font-size: 16px;
      }
    }

    .confirm-page-discount {
      justify-content: center;
      width: 100%;
      font-size: 22px;
      line-height: 21px;
      border-top: 1px solid #c6c6c6;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      color: #2b273b;
      padding-top: 20px;

      .discount {
        font-size: 16px;
        float: left;
        text-align: start;
        width: 250px;
        margin-bottom: 20px;
      }

      .price {
        display: inline;
        font-size: 16px;
        float: right;
      }
    }

    .confirm-page-mobile-service-fee {
      justify-content: center;
      width: 100%;
      border-top: 1px solid #c6c6c6;
      padding-top: 20px;
      font-size: 22px;
      line-height: 21px;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      color: #2b273b;
      padding-bottom: 20px;

      .service {
        display: inline;
        font-size: 16px;
        float: left;
        text-align: start;
      }

      .price {
        display: inline;
        font-size: 16px;
        float: right;
      }

      .customer-image-final-suggestions {
        background-color: #e5e6ea;
        margin-bottom: 20px;
        margin-top: 60px;
      }
    }

    .new-pricing-lead-text {
      width: 400px;
      height: 200px;
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 14px;
      text-align: justify;
      color: "#262626";
    }

    .pet-widget {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    .thank-you-page {
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      margin-top: 56px;
      color: #2b273b;
    }

    .link {
      margin-top: -25px;
    }

    .address-selector-row-content {
      &.multiple {
        padding-left: 8px;
        padding-bottom: 16px;

        .address-selector-option {
          border-top: 1px solid #dedede;
          width: 100%;
          padding-top: 5px;
          margin-bottom: 15px;
          height: 45px !important;
        }

        .address-selector-option:last-child {
          margin-bottom: 0px !important;
        }
      }
    }

    .multiple-address-selection {
      font-family: "Plain Medium";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      color: #191919;
      margin-left: 20px;
      cursor: pointer;
      width: 330px;
    }

    .multiple-address-selection-unchecked {
      font-family: "Plain";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: #737373;
      margin-left: 20px;
      cursor: pointer;
      width: 330px;
    }

    .address-description-text {
      font-family: "Plain";
      line-height: "21px";
      font-size: 15;
      font-weight: "normal";
      text-align: "left";
      margin-top: 10px;
    }

    .selected-address-description-text {
      font-weight: "bold";
      color: #191919;
    }

    .selected-address-row-container:not(:first-child) {
      margin-top: 20px;
    }

    .service-description-show-more {
      color: #737373;
      text-decoration: underline;
      cursor: pointer;
      padding-left: 10px;
    }

    .service-description-text-content {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .service-description-expanded {
      -webkit-line-clamp: unset;
    }

    .appointment-suggestion {
      width: 100% !important;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 8px;
      margin: 10px auto;
      line-height: 26px;
      font-size: 16px;
      padding: 15px;
      text-align: left;

      .appointment-image {
        border-radius: 8px;
      }

      button {
        width: 100%;
        height: 46px;
      }

      .button-booked {
        background-color: #9d9d9d;
        border-radius: 8px;
        color: #fff;
        width: 286px;
        height: 46px;
      }
    }

    .appointment-suggestion-header {
      .suggestion-date-secondary {
        font-family: "Plain Medium";
        font-weight: 600 !important;
        color: #191919;
        font-size: 17px;
      }

      .suggestion-time-secondary {
        font-family: "Plain";
        color: #2b273d;
        font-weight: 400 !important;
        font-size: 17px;
      }
      .suggestion-context-secondary {
        font-family: "Plain";
        color: #2b273d;
        font-weight: 400 !important;
        font-size: 17px;
      }
      .suggestion-stylist-secondary {
        color: #737373;
        font-size: 15px;
      }

      .suggestion-date {
        font-family: "Plain Medium";
        font-weight: 700 !important;
      }

      .suggestion-time {
        font-family: "Plain Medium";
        font-weight: 700 !important;
      }

      .suggestion-context {
        color: #737373;
      }

      .suggestion-stylist {
        color: #737373;
      }
    }

    input[type="number"] {
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .upcoming-appointment-item {
    cursor: pointer;
  }

  .appointment-detail-text {
    font-family: "Plain";
    font-size: 17px;
    line-height: 25px;
  }

  .suggestion-filters {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 20px;

    .MuiInputBase-root {
      border-radius: 6px !important;
    }

    .specific-date-picker {
      .MuiInputBase-root {
        height: 59px;
      }
    }
  }

  .suggestions-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .suggestions-sorry-message {
      width: 100%;
      text-align: left;
      font-size: 17px;
      line-height: 24px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}

.standard-tooltip {
  font-size: 14px !important;
  margin: 0 auto;
  line-height: 20px;
  pointer-events: auto !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600");

@import "./add-event.scss";

.customer-filters-wrapper {
  position: relative;
  display: inline-block;
}

@import "@marathon/web/styles/core-components.scss";

.code-verification {
  margin-top: 20px;

  --ReactInputVerificationCode-itemWidth: 69px;
  --ReactInputVerificationCode-itemHeight: 79px;
  --ReactInputVerificationCode-itemSpacing: 10px;

  .ReactInputVerificationCode__container {
    width: 100%;
  }

  .ReactInputVerificationCode__item {
    &:active {
      border: 1px solid #0066ff;
    }

    border-radius: 8px;
  }

  .ReactInputVerificationCode__item.is-active {
    border: 2px solid #0066ff;
  }
}

.referral-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dbffe5;
  color: #0d612a;
  font-weight: 600;
  line-height: 24px;
  padding: 10px;

  .banner-title {
    font-size: 17px;
  }

  .banner-subtitle {
    font-size: 12px;
  }
}

.line-divider {
  width: 100%;
  height: 1px;
  background-color: #d6d8da;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .online-booking-wizard-container {
    .titles-container {
      margin-top: 0px;

      h1 {
        font-size: 25px;
        line-height: 30px;
      }

      h2 {
        font-size: 17px;
        line-height: 24px;
        margin-top: 10px;
      }
    }

    .online-booking-step-container {
      margin-top: 0;
      padding-left: 16px;
      padding-right: 16px;

      .appointment-suggestion {
        button {
          height: 38px;
        }
      }

      .parking-notes {
        width: 100%;
        height: 50px;
        margin-top: 20px;
      }
    }
  }

  .confirm-page-footer {
    flex-direction: column;

    & > div {
      width: 100% !important;
    }

    & > div:first-child {
      margin-bottom: 20px;
    }
  }

  .confirm-page-price {
    width: 100% !important;
  }

  .confirm-page-service {
    width: 100% !important;

    .pet-name {
      width: 90% !important;
    }
  }

  .confirm-page-mobile-service-fee {
    width: 100% !important;

    .service {
      display: inline;
      float: left;
      text-align: start;
    }

    .price {
      display: inline;
      float: right;
    }
  }

  .code-verification {
    --ReactInputVerificationCode-itemWidth: 50px;
    --ReactInputVerificationCode-itemHeight: 59px;
    --ReactInputVerificationCode-itemSpacing: 4px;
  }

  .pricing-row-service-description {
    width: 100%;
  }

  .ReactInputVerificationCode__item.is-active {
    border: 2px solid #0066ff;
  }

  .content-safety {
    font-size: 15px !important;

    & > div {
      margin-top: 10px !important;
    }

    & > div:first-child {
      margin-top: 0px !important;
    }
  }
}
